<template>
  <div>
    <v-container :fluid="section.attributes.fullWidth">
      <v-row justify="center" v-if="section.attributes.title" class="py-6">
        <div class="text-h5">{{ section.attributes.title }}</div>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" :md="section.attributes.width ? section.attributes.width : 8">
          <vue-simple-markdown v-if="section.attributes.type === 'md'" :source="section.content"></vue-simple-markdown>
          <v-carousel v-else-if="section.attributes.type === 'carousel'" cycle continuous show-arrows-on-hover dark :height="section.attributes.height > 0 ? section.attributes.height : 500">
            <v-carousel-item v-for="(item, index) in section.content" :key="'section-carousel-' + index" :src="item.imageUrl">
              <v-row class="fill-height" align="end" justify="center">
                <v-card color="rgba(255, 255, 255, 0.75)" class="mb-12 py-4 px-12 text-center">
                  <div class="text-h5 grey--text text--darken-4">{{ item.title }}</div>
                </v-card>
              </v-row>
            </v-carousel-item>
          </v-carousel>
          <div v-else-if="section.attributes.type === 'html'" v-html="section.content"></div>
          <v-row v-else-if="section.attributes.type === 'cards'" justify="center">
            <v-col v-for="(item, index) in section.content" :key="'section-card-' + index" cols="12" :md="item.width">
              <v-card>
                <v-img :src="item.imageUrl" :max-height="item.imageHeight"></v-img>
                <v-card-title v-if="item.title">{{ item.title }}</v-card-title>
                <v-card-subtitle v-if="item.subtitle">{{ item.subtitle }}</v-card-subtitle>
                <v-card-text v-if="item.text">{{ item.text }}</v-card-text>
                <v-card-actions v-if="item.action">
                  <v-btn color="primary" text>
                    <router-link :to="item.action.url">
                      {{ item.action.label }}
                    </router-link>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <div v-else>{{ section.content }}</div>
        </v-col>
      </v-row>
    </v-container>
    <v-divider class="mt-2"></v-divider>
  </div>
</template>

<script>
export default {
  name: 'PostSection',
  props: {
    section: {
      attributes: Object,
      content: undefined
    }
  }
}
</script>

<style scoped>

</style>
