<template>
  <div>
    <v-img max-height="500"
           class="text--primary align-end"
           :src="header.bannerImg">
      <v-row class="fill-height" align="end" justify="center">
        <v-card color="rgba(255, 255, 255, 0.85)" class="mb-12 py-6 px-12 text-center">
          <div class="text-h4">{{ header.title }}</div>
        </v-card>
      </v-row>
    </v-img>
    <v-divider class="mb-2"></v-divider>
    <v-row justify="center" v-if="header.summary" class="my-6">
      <v-col cols="12" md="6">
        <v-card outlined>
          <v-card-text class="text-center">{{ header.summary }}</v-card-text>
          <v-card-text class="text-center" v-if="header.date">{{ header.date }}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'PostHeader',
  props: {
    header: {
      bannerImg: String,
      title: String,
      summary: String,
      date: String
    }
  }
}
</script>

<style scoped>

</style>
