<template>
  <div>
    <post-header :header="post.header"></post-header>
    <post-section v-for="(section, index) in post.sections"
                  :key="'post-section-' + index"
                  :section="section"></post-section>
  </div>
</template>

<script>
import PostHeader from './PostHeader'
import PostSection from './PostSection'
export default {
  name: 'Post',
  components: { PostSection, PostHeader },
  props: {
    post: {
      header: Object,
      sections: Array
    }
  }
}
</script>

<style scoped>

</style>
