<template>
  <post :post="postData"></post>
</template>

<script>
import Post from '../components/Post'
import news1 from '../mock/news_1.json'
import news2 from '../mock/news_2.json'
export default {
  name: 'News',
  components: { Post },
  data: function () {
    return {
      slug: this.$route.params.slug,
      news1: news1
    }
  },
  computed: {
    postData: function () {
      if (this.slug === 'BiPV_Solar_Glass:_Turn_Your_Building_into_a_Power_Generator') {
        return news1
      } else {
        return news2
      }
    }
  }
}
</script>

<style scoped>

</style>
